<script>
import VIcon from '@/components/Icon.vue';

export default {
  components: {
    VIcon,
  },
  props: {
    bgColor: String,
    radius: Boolean || String,
    rebranding: Boolean,
    icon: String,
    label: String,
    loading: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<template>
  <button
    v-bind:class="{ 'loading': loading && $store.state.formLoading,
    'button__blue--rebranding': bgColor === 'blue' && rebranding,
    'button__radius--rebranding': radius && rebranding,
    'button--rebranding': rebranding }"
    v-on:click="click" class="button">
    <div class="button__content">
      <span v-html="label" class="button__content__text"/>
      <!-- <v-icon v-if="icon" class="button__content__icon" name="arrow-right"/> -->
    </div>
    <v-icon class="button__loading" name="loading"/>
  </button>
</template>

<style lang="postcss" scoped>
.button {
  background-color: var(--color-green);
  border-radius: .25rem;
  color: var(--color-white);
  cursor: pointer;
  display: block;
  font-size: .75rem;
  font-weight: bold;
  height: 3.25rem;
  text-transform: uppercase;
  transition: background-color .25s;
  min-width: 100%;
  &:not(.loading):hover {
    /* background-color: #11b713; */
    text-decoration: underline;
    ^&__icon {
      transform: translateX(.5rem);
    }
  }
  &.disabled {
    cursor: default;
    background-color: var(--color-grey-lighten);
  }
  &.loading {
    cursor: default;
    ^&__content {
      display: none;
    }
    ^&__loading {
      display: inline-block;
    }
  }
  &--rebranding {
    font-size: 1rem;
    line-height: 1rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.33);
  }
  &__blue {
    &--rebranding {
      background-color: var(--color-blue-onlypay);
    }
  }
  &__radius {
    &--rebranding {
      border-radius: 3rem;
    }
  }
  &__content {
    align-items: center;
    display: flex;
    justify-content: center;
    &__icon {
      color: var(--color-white);
      margin: -.125rem 0 0 .5rem;
      size: 1.5rem;
      transition: transform .25s;
    }
  }
  &__loading {
    animation: spin 1.2s infinite linear;
    color: var(--color-white);
    display: none;
    size: 1.5rem;
  }
}
</style>
