var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button",
      class: {
        loading: _vm.loading && _vm.$store.state.formLoading,
        "button__blue--rebranding": _vm.bgColor === "blue" && _vm.rebranding,
        "button__radius--rebranding": _vm.radius && _vm.rebranding,
        "button--rebranding": _vm.rebranding,
      },
      on: { click: _vm.click },
    },
    [
      _c("div", { staticClass: "button__content" }, [
        _c("span", {
          staticClass: "button__content__text",
          domProps: { innerHTML: _vm._s(_vm.label) },
        }),
      ]),
      _c("v-icon", {
        staticClass: "button__loading",
        attrs: { name: "loading" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }